import { createSlice } from "@reduxjs/toolkit";


const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: sessionStorage.getItem('items') ? JSON.parse(sessionStorage.getItem('items')) : []
  },
  reducers: {

    addToCart: (state, action) => {
      const item = action.payload;
      const index = state.items.findIndex(existingItem => existingItem.id === item.id);

      if (index !== -1) {
        // Item already exists in cart increase quantity
        state.items[index].quantity += 1;

      } else {
        // add it
        state.items.push(item);
      }
      sessionStorage.setItem("items", JSON.stringify(state.items))
    },

    decreaseQuantity: (state, action) => {
      const id = action.payload;
      console.log(id);
      const existingItem = state.items.find(i => i.id === id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.items = state.items.filter(i => i.id !== id);
        }
      }
      sessionStorage.setItem("items", JSON.stringify(state.items))
    },

    removeFromCart: (state, action) => {
      const itemId = action.payload;
      const index = state.items.findIndex(item => item.id === itemId);
      console.log(index, 'hh');

      if (index !== -1) {
        state.items.splice(index, 1)
      }
      sessionStorage.setItem("items", JSON.stringify(state.items))
    },


    clearCart: state => {
      state.items = [];
      sessionStorage.setItem("items", JSON.stringify(state.items))
    },
  }
})


export const { addToCart, removeFromCart, clearCart, decreaseQuantity } = cartSlice.actions
export default cartSlice.reducer

export const selectCartItems = state => state.cart.items
export const selectCartTotal = state => {
  const items = state.cart.items
  const total = items.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0)
  return total
}

export const selectCartItemsWithId = (state, id) => state.cart.items.filter((item) => item.id === id)