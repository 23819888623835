import React, { useEffect, useState } from 'react'
import './Cards.css'
import img1 from './food1.jpg'
import i1 from './i1.jpg'
import i2 from './i2.jpg'
import i3 from './i3.jpg'
import i4 from './i4.jpg'
import i5 from './i5.jpg'
import i6 from './i6.jpg'
import i7 from './i7.jpg'
import i8 from './i8.jpg'
import i9 from './i9.jpg'
import i10 from './i10.jpg'
import i11 from './i11.jpg'
import i12 from './i12.jpg'

import i13 from './i13.jpg'
import i14 from './i14.jpg'
import i15 from './i15.jpg'
import i16 from './i16.jpg'
import i17 from './i17.jpg'
import i18 from './i18.jpg'
import i19 from './i19.jpg'
import i20 from './i20.jpg'
import { BiRupee } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, selectCartItems, selectCartItemsWithId } from '../../features/cart/cartSlice'


const data = [
    {
        img: i1,
        title: "Veg Fried Rice",
        price: 115,
        description:
            "Serve 1 | Medium spicy | Rice and vegetables tossed with spices and sauces to make it taste amazing. Pair up with manchurian and complete your meal. | Served with Hot Sauce | NO MSG/AJINOMOTTO USED.",
    },
    {
        img: i2,
        title: "Gobi Manchurian Dry",
        price: 89,
        description:
            "Served with tomato sauce(no artificial colours/ajinomoto/msg and its chemical free)..",
    },
    {
        img: i3,
        title: "Sprout Salad",
        price: 150,
        description: "Green moong and black chana sprouts with lemon juice and salt.",
    },
    {
        img: i4,
        title: "Paneer Chilla",
        price: 169,
        description:
            "Tasty moong dal pancake stuffed with cottage cheese, spices, and herbs, piping hot high in protien and a very healthy option.",
    },
    {
        img: i5,
        title: "Chole Bhature",
        price: 189,
        description:
            "Spicy chole gravy cooked in a delectable veriety of masalas, served with a fluffy fried leavened bread",
    },
    {
        img: i6,
        title: "Club Kachori",
        price: 199,
        description:
            "Kolkata'sfavourite breakfast. A spicy hing wali potato gravy served with small masala filled poori (6 pcs )",
    },
    {
        img: i7,
        title: "Egg Curry with Rice",
        price: 219,
        description:
            "Serve 1 | A popular dish from Kolkata | Egg curry cooked with special spices and served with Basmati Rice | NO MSG/AJINOMOTTO USED.",
    },
    {
        img: i8,
        title: "Masala Maggi",
        price: 79,
        description:
            "Serve 1 | Maggi cooked with spices and herbs to make it taste delicious | NO MSG/AJINOMOTTO USED.",
    },
    {
        img: i9,
        title: "Chilli Cheese Toast Oven Tost",
        price: 189,
        description:
            "Covered in cheese chilli and desi spices grilled intawa for a desi feel.",
    },
    {
        img: i12,
        title: 'Paneer Paratha (Desi Ghee)',
        price: 489,
        description:
            'One of the delicious indian flatbread made with cottage cheese stuffing & served with our special chilli pickle & raita',
    },
    {
        img: i10,
        title: 'Aloo Paratha (Desi Ghee)',
        price: 329,
        description:
            'Made with whole wheat flatbread stuffed with spicy mashed potatoes. Tawa fried in desi ghee , served with our special chilli pickle & raita',
    },
    {
        img: i11,
        title: 'Gobi Paratha (Desi Ghee)',
        price: 289,
        description:
            'Unleavened whole wheat flatbread stuffed with a spiced grated cauliflower filling, & served with our special chilli pickle & raita',
    },
    {
        img: i16,
        title: 'Palak Paneer',
        price: 249,
        description:
            'The spinach is blanched and then pureed. This curry is made with spinach and Indian cottage cheese.',
    },
    {
        img: i15,
        title: 'Kaju Masala',
        price: 289,
        description:
            'Roasted cashews cooked in white and cashew nut gravy, crunchiness of cashew topper and garnish with creams. Mildly sweet in taste.',
    },
    {
        img: i14,
        title: 'Paneer Butter Masala',
        price: 229,
        description:
            'Paneer Butter Masala is a tasty Indian delicacy that can be served with paratha, naan, or rice of your choice.',
    },
    {
        img: i13,
        title: 'Chilly Mushroom With Fried Rice',
        price: 489,
        description: 'Basmati rice tossed with bellpepper onin and beans and served with spicey chilly mushroom grevy',
    },
    {
        img: i17,
        title: 'roti (2 pcs)',
        price: 49,
        description: 'Wholesome and soft roti made from wheat flour, gram flour.',
    },
    {
        img: i18,
        title: 'Plain Dosa',
        price: 89,
        description: 'Enticing crispy dosa, with sumptuous fresh butter spread on it.',
    },
    {
        img: i19,
        title: 'Veg Dum Biryani',
        price: 99,
        description: 'A dish in which rice is prepared with fried onions, corriander and fresh vegetables with a dash of milk. Also, generous amount of nuts are used to enhance the flavours',
    },
    {
        img: i20,
        title: 'Paneer Biryani',
        price: 119,
        description: 'Deliciously decadent flavored dum rice layered with paneer and Indian whole spices cooked in a pot.',
    }

];

const Card = ({ title, description, price, onClick, image,id }) => {
    const items = useSelector((state) => selectCartItemsWithId(state, id))

    return (
        <div className="card">
            <img src={image} alt="food" style={{ width: "100%", height: "220px", objectFit: 'cover', borderRadius: '20px 20px 0 0' }} />
            <div className='pri'>
                <p className='main-desc'>{title} </p>
                <span><BiRupee /> {price}</span>
            </div>
            <p className='sub-desc'>{description}</p>

            <hr style={{ marginLeft: "10px", marginRight: "10px" }} />
            <div>
                <button onClick={onClick} className="favorite styled"
                    type="button">
                   {items.length?"Added": 'Add to cart'}
                </button>
            </div>
        </div>
    )

}







function Cards() {

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const items = useSelector(selectCartItems)

    const onClick = (id, title, description, price, img, quantity) => {


        const item = {
            id,
            title,
            description,
            price,
            img,
            quantity: 1
        }
        console.log(item,'single');
        dispatch(addToCart(item))

        console.log(items, 'items');
    }


    return (
        <>

            <div className='kiti-card '>
                {data.map((item, index) => {
                    return <Card key={index} id={index} title={item.title} description={item.description} price={item.price} image={item.img}
                        onClick={() => onClick(index, item.title, item.description, item.price, item.img)} />
                })}
            </div>

        </>
    )
}

export default Cards