import React from 'react'

const TermsCondition = () => {
    return (
        <div  style={{padding:'10px'
    }}>
            <p>
                <h1 style={{ textAlign: 'center' }}>Benak Foods - Terms of Use</h1>
                <ul>


                    <li>
                        This document is an electronic record in terms of Information Technology
                        Act, 2000 and rules there under as applicable and the amended provisions
                        pertaining to electronic records in various statutes as amended by the
                        Information   Technology   Act,   2000.   This   document   is   published   in
                        accordance with the provisions of Rule 3 (1) of the Information Technology
                        (Intermediaries guidelines) Rules, 2011 that require publishing the rules
                        and regulations, privacy policy and Terms of Use for access or usage of
                        www.Benak Foods.com website and Benak Foods application for mobile
                        and handheld devices.
                    </li>
                    <br/>
                    <h3>
                        Terms of Use
                    </h3>
                    <li>
                        1. These terms of use (the "Terms of Use") govern your use of our
                        website www.Benak Foods.com (the "Website") and our "Benak
                        Foods" application for mobile and handheld devices (the "App").
                        The   Website   and   the   App   are   jointly   referred   to   as   the
                        "Platform". Please read these Terms of Use carefully before you
                        use the services. If you do not agree to these Terms of Use, you
                        may not use the services on the Platform, and we request you to
                        uninstall   the   App.   By   installing,   downloading   or   even   merely
                        using the  Platform, you shall be contracting with Benak Foods
                        and you signify your acceptance to this Terms of Use and other
                        Benak   Foods   policies   (including   but   not   limited   to   the
                        Cancellation   &   Refund   Policy,   Privacy   Policy   and   Take   Down
                        Policy)   as   posted   on   the   Platform   and   amended   from   time   to
                        time,   which   takes   effect   on   the   date   on   which   you   download,
                        install   or   use   the   Platform,   and   create   a   legally   binding
                        arrangement to abide by the same.
                    </li>
                    <br/>
                    <li>
                        2. The   Platform   is   owned   and   operated   by   Bundl   Technologies
                        Private   Limited,   a   private   limited   company   incorporated   under
                        the Companies Act, 1956 and having its registered office at 5th
                        Floor,C/O-   SHASHI   BHUSHAN,   LH-150,   NEAR   KIDWAIPURI   POST
                        OFFICE, ROAD NO 11, S K NAGAR, PATNA, Bihar-800001, India.
                        For the purpose of these Terms of Use, wherever the context so
                        requires, "you", “user”, or “User” shall mean any natural or legal
                        person   who   shall   transaction   on   the   Platform   by   providing
                        registration data while registering on the Platform as a registered
                        user   using   any   computer   systems.   The   terms   "Benak   Foods",
                        "we",   "us"   or   "our"   shall   mean   Bundl   Technologies   Private
                        Limited.
                    </li>
                    <br/>

                    <li>
                        3. Benak   Foods   enables   transactions   on   its   Platform   between
                        participating   restaurants/merchants   and   buyers,   dealing   in   (a)
                        prepared food and beverages, (b) consumer goods, and (c) other
                        products   and   services   ("Platform   Services").   The   buyers
                        ("Buyer/s")   can   choose   and   place   orders   ("Orders")   from   a
                        variety  of products and services listed  and offered for  sale by
                        various merchants including but not limited to the restaurants,
                        eateries   and   grocery   stores   ("Merchant/s"),   on   the   Platform.
                        Further, the Buyer can also place Orders for undertaking certain
                        tasks on the Platform (“Tasks”).
                    </li>
                    <br/>

                    <li>
                        4. Benak Foods enables delivery of such Orders or completion of
                        Tasks   at   select   localities   of   serviceable   cities   across   India
                        ("Delivery   Services")   by   connecting   third   party   service
                        providers i.e. pick-up and delivery partners (“PDP”) who will be
                        responsible for providing the pick-up and delivery services and
                        completing Tasks initiated by the users of the Platform (Buyers or
                        Merchants).   The   Platform   Services   and   Delivery   Services   are
                        collectively referred to as "Services". For both Platform Services
                        and   Delivery   Services,   Benak   Foods   is   merely   acting   as   an
                        intermediary   between   the   Merchants   and   Buyers   and/or   PDPs
                        and Buyers/Merchants.
                        PDPs  are individual  entrepreneurs  engaged  with  Benak  Foods  on a
                        voluntary,   non-exclusive   and   principal   to   principal   basis   to   provide
                        aforementioned   services   for   service   fee.   PDPs   are   independent
                        contractors and are free to determine their timings of work.    Benak
                        Foods   does   not   exercise   control   on   the   PDPs   and   the   relationship
                        between   the   PDPs   and   Benak   Foods   is   not   that   of   an   agent   and
                        principal or employee and employer.
                    </li>
                    <br/>


                    <li> 5. For the pickup and delivery services and completing the Tasks,
                        PDPs   may   charge   the   users   of   the   Platform   (Buyers   or
                        Merchants), a service fee (inclusive of applicable taxes whenever
                        not   expressly   mentioned)   determined   on   the   basis   of   various
                        factors including but not limited to distance covered, time taken,
                        demand for delivery services/Tasks, real time analysis of traffic
                        and   weather   conditions,   seasonal   peaks   or   such   other
                        parameters as may be determined from time to time.
                    </li>
                    <br/>
                    <li>
                        6. Amendments
                        These Terms of Use are subject to modifications. We reserve the right
                        to   modify   or   change   these   Terms   of   Use   and   other   Benak   Foods
                        policies at any time by posting modified documents on the Platform
                        and notifying you of the same for your perusal. You shall be liable to
                        update yourself of such changes, if any, by accessing the same. You
                        shall, at all times, be responsible for regularly reviewing the Terms of
                        Use and the other Benak Foods policies and note the changes made
                        on   the   Platform.   Your   continued   usage   of   the   Services   after   any
                        change is posted constitutes your acceptance of the amended Terms
                        of Use and other Benak Foods policies. As long as you comply with
                        these   Terms   of   Use,   Benak   Foods   grants   you   a   personal,   non-
                        exclusive,   non-transferable,   limited   privilege   to   access,   enter,   and
                        use the Platform. By accepting these Terms of Use, you also accept
                        and agree to be bound by the other terms and conditions and Benak
                        Foods   policies   (including   but   not   limited   to   Cancellation   &   Refund
                        Policy, Privacy Policy and Notice and Take Down Policy) as may be
                        posted on the Platform from time to time.
                    </li>
                    <br/>

                    <li>
                        7. Use of Platform and Services
                    </li>
                    <br/>

                    <li>
                        1. All commercial/contractual terms are offered by and agreed
                        to   between   Buyers   and   Merchants   alone   with   respect   to
                        products and services being offered by the Merchants. The
                        commercial/contractual   terms   include   without   limitation
                        price, applicable taxes, shipping costs, payment terms, date,
                        period and mode of delivery, warranties related to products
                        and services and after sales services related to products and
                        services. Benak Foods does not have any control or does not
                        determine   or   advise   or   in   any   way   involve   itself   in   the
                        offering or acceptance of such commercial/contractual terms
                        between   the   Buyers   and   Merchants.   Benak   Foods   may,
                        however, offer support services to Merchants in respect to
                        Order fulfilment, mode of payment, payment collection, call
                        centre   support   and   other   ancillary   services,   pursuant   to
                        independent   contracts   executed   by   Benak   Foods   with   the
                        Merchants. The price of the product and services offered by
                        the   Merchant   are   determined   by   the   Merchant   itself   and
                        Benak Foods has no role to play in such determination of
                        price in any way whatsoever.
                    </li>
                    <br/>

                    <li>
                        2. Upon   acceptance   of   any   Order   or   Task   by   the   PDPs,   the
                        pickup and delivery services or Task completion services (as
                        the case may be) undertaken by him/her, shall constitute a
                        separate   contract   for   services   between   Merchants/Buyers
                        and   PDPs.   Benak   Foods   shall   not   be   responsible   for   the
                        services provided by PDP to Merchants/Buyers through the
                        Platform. Benak Foods may, however, offer support services
                        to PDPs in respect of Order fulfilment, payment collection,
                        call  centre   support,   and  other   ancillary   services,   pursuant
                        to independent contracts executed by Benak Foods with the
                        PDPs.
                    </li>
                    <br/>
                    <li>
                        3. Benak Foods does not make any representation or warranty
                        as to the item-specifics (such as legal title, creditworthiness,
                        identity, etc.) of any of the Merchants. You are advised to
                        independently   verify   the   bona   fides   of   any   particular
                        Merchant that you choose to deal with on the Platform and
                        use your best judgment on that behalf. All Merchant offers
                        and third-party offers are subject to respective party terms
                        and conditions. Benak Foods takes no responsibility for such
                        offers.
                    </li>
                    <br/>

                    <li>
                        4. Benak Foods neither make any representation or warranty
                        as to specifics (such as quality, value, salability, etc.) of the
                        products or services proposed to be sold or offered to be
                        sold   or   purchased   on   the   Platform   nor   does   implicitly   or
                        explicitly   support   or   endorse   the   sale   or   purchase   of   any
                        products or services on the Platform. Benak Foods accepts
                        no liability for any errors or omissions, whether on behalf of
                        itself or third parties.
                    </li>
                    <br/>
                    <li>

                        5. Benak Foods does not make any representation or warranty
                        with respect to any aspect of the services being provided by
                        the PDPs through the Platform including but not limited to
                        pick up and delivery services and Task completion services
                        to the Merchants or Buyers as the case may be.
                    </li>
                    <br/>
                    <li>

                        6. Benak Foods is not responsible for any non-performance or
                        breach   of   any   contract   entered   into   between   Buyers   and
                        Merchants, and between Merchants/Buyers and PDP on the
                        Platform. Benak Foods cannot and does not guarantee that
                        the concerned Buyers, Merchants and PDPs will perform any
                        transaction concluded on the Platform. Benak Foods is not
                        responsible   for   unsatisfactory   or   non-performance   of
                        services or damages or delays as a result of products which
                        are out of stock, unavailable or back ordered.
                    </li>
                    <br/>
                    <li>



                        7. Benak   Foods   is   operating   an   online   marketplace   and
                        assumes the role of facilitator, and does not at any point of
                        time   during any  transaction between  Buyer  and  Merchant
                        and/or   Buyer   and   PDP   on   the   Platform   come   into   or   take
                        possession   of   any   of   the   products   or   services   offered   by
                        Merchant   or   PDP.   At   no   time   shall   Benak   Foods   hold   any
                        right,   title   or   interest   over   the   products   nor   shall   Benak
                        Foods have any obligations or liabilities in respect of such
                        contract entered into between Buyer and Merchant and/or
                        Buyer and PDP.
                    </li>
                    <br/>
                    <li>
                        8. Benak Foods is only providing a platform for communication
                        and   it   is   agreed   that   the   contract   for   sale   of   any   of   the
                        products   or   services   shall   be   a   strictly   bipartite   contract
                        between the Merchant and the Buyer. In case of complaints
                        from the Buyer pertaining to efficacy, quality, or any other
                        such issues, Benak Foods shall notify the same to Merchant
                        and shall also redirect the Buyer to the consumer call center
                        of the Merchant. The Merchant shall be liable for redressing
                        Buyer complaints. In the event you raise any complaint on
                        any Merchant accessed using our Platform, we shall assist
                        you   to   the   best   of   our   abilities   by   providing   relevant
                        information to you, such as details of the Merchant and the
                        specific   Order   to   which   the   complaint   relates,   to   enable
                        satisfactory resolution of the complaint.

                    </li>
                    <br/>




                    <li>

                        9. Similar   to   the   above,   Benak   Foods   is   only   providing   a
                        platform for communication with PDP and does not provide
                        any   pick-up   and   delivery   services   or   Task   completion
                        services   with   respect   to   the   Orders   placed   by
                        Merchants/Buyers on the Platform as it is merely facilitating
                        Delivery Services by connecting the Merchants/Buyers with
                        the PDP through the Platform. In case of complaints by the
                        Merchants/Buyers   for   deficiency   or   lapse   in   the   delivery
                        services or Task completion services provided by PDP, Benak
                        Foods   shall   notify   the   same   to   the   PDP   and   also   assist
                        Merchants/Buyers   to   the   best   of   its   abilities   to   enable
                        satisfactory resolution of the complaint.
                    </li>
                    <br/>
                    <li>

                        10.Please   note   that   there   could   be   risks   in   dealing   with
                        underage persons or people acting under false pretence.
                    </li>
                    <br/>
                    <li>
                        11.Benak Foods - Use of the Website and Apps (Android and
                        iOS)
                    </li>
                    <br/>

                    <li>
                        You agree, undertake and confirm that your use of Platform shall
                        be strictly governed by the following binding principles:
                        1. You shall not host, display, upload, download, modify,
                        publish,   transmit,   update   or   share   any   information
                        which:
                    </li>
                    <br/>

                    <li>

                        1. belongs to another person and which you do not
                        have any right to;
                    </li>
                    <br/>
                    <li>
                        2. is   grossly   harmful,   harassing,   blasphemous,
                        defamatory,   obscene,   pornographic,   paedophilic,
                        libellous,   slanderous,   criminally   inciting   or
                        invasive of another's privacy, hateful, or racially,
                        ethnically   objectionable,   disparaging,   relating   or
                        encouraging   money   laundering   or   gambling,   or
                        otherwise unlawful in any manner whatsoever; or
                        unlawfully   threatening   or   unlawfully   harassing
                        including   but   not   limited   to   "indecent
                        representation of women" within the meaning of
                        the   Indecent   Representation   of   Women
                        (Prohibition) Act, 1986;
                    </li>
                    <br/>

                    <li>

                        3. is misleading or misrepresentative in any way;
                    </li>
                    <br/>
                    <li>
                        4. is   patently   offensive   to   the   online   community,
                        such as sexually explicit content, or content that
                        promotes obscenity, paedophilia, racism, bigotry,
                        hatred or physical harm of any kind against any
                        group or individual;
                    </li>
                    <br/>
                    <li>

                        5. harasses   or   advocates   harassment   of   another
                        person;
                    </li>
                    <br/>
                    <li>
                        6. involves   the   transmission   of   "junk   mail",   "chain
                        letters",   or   unsolicited   mass   mailing   or
                        "spamming";
                    </li>
                    <br/>

                    <li>
                        7. promotes   illegal   activities   or   conduct   that   is
                        abusive,   threatening,   obscene,   defamatory   or
                        libellous;
                    </li>
                    <br/>

                    <li>
                        8. infringes upon or violates any third party's rights
                        including, but not limited to, intellectual property
                        rights,   rights   of   privacy   (including   without
                        limitation   unauthorized   disclosure   of   a   person's
                        name, email address, physical address or phone
                        number) or rights of publicity;
                    </li>
                    <br/>

                    <li>
                        9. promotes   an   illegal   or   unauthorized   copy   of
                        another   person's   copyrighted   work   (see
                        "copyright   complaint"   below   for   instructions   on
                        how   to   lodge   a   complaint   about   uploaded
                        copyrighted   material),   such  as   providing   pirated
                        computer   programs   or   links   to   them,   providing
                        information   to   circumvent   manufacture-installed
                        copy-protect devices, or providing pirated music
                        or links to pirated music files;
                    </li>
                    <br/>

                    <li>
                        10.contains   restricted   or   password-only   access
                        pages,   or   hidden   pages   or   images   (those   not
                        linked to or from another accessible page);
                        11.provides material that exploits people in a sexual,
                        violent   or   otherwise   inappropriate   manner   or
                        solicits personal information from anyone;
                    </li>
                    <br/>

                    <li>

                        12.provides   instructional   information   about   illegal
                        activities   such   as   making   or   buying   illegal
                        weapons,   violating   someone's   privacy,   or
                        providing or creating computer viruses;
                    </li>
                    <br/>
                    <li>

                        13.contains video, photographs, or images of another
                        person (with a minor or an adult);
                    </li>
                    <br/>
                    <li>

                        14.tries to gain unauthorized access or exceeds the
                        scope of authorized access to the Platform or to
                        profiles, blogs, communities, account information,
                        bulletins,   friend   request,   or   other   areas   of   the
                        Platform   or   solicits   passwords   or   personal
                        identifying information for commercial or unlawful
                        purposes from other users;
                    </li>
                    <br/>
                    <li>
                        15.engages   in   commercial   activities   and/or   sales
                        without   our   prior   written   consent   such   as
                        contests,   sweepstakes,   barter,   advertising   and
                        pyramid   schemes,   or   the   buying   or   selling   of
                        products   related   to   the   Platform.   Throughout
                        these Terms of Use, Benak Foods's prior written
                        consent   means   a   communication   coming   from
                        Benak   Foods's   Legal   Department,   specifically   in
                        response   to   your   request,   and   expressly
                        addressing   and   allowing   the   activity   or   conduct
                        for which you seek authorization;
                    </li>
                    <br/>

                    <li>
                        16.solicits   gambling   or   engages   in   any   gambling
                        activity which is or could be construed as being
                        illegal;
                    </li>
                    <br/>

                    <li>

                        17.interferes with another user's use and enjoyment
                        of   the   Platform   or   any   third   party's   user   and
                        enjoyment of similar services;
                    </li>
                    <br/>
                    <li>
                        18.refers   to   any   website   or   URL   that,   in   our   sole
                        discretion, contains material that is inappropriate
                        for   the   Platform   or   any   other   website,   contains
                        content that would be prohibited or violates the
                        letter or spirit of these Terms of Use;
                    </li>
                    <br/>

                    <li>
                        19.harm minors in any way;
                    </li>
                    <br/>

                    <li>
                        20.infringes   any   patent,   trademark,   copyright   or
                        other intellectual property rights or third party's
                        trade secrets or rights of publicity or privacy or
                        shall   not   be   fraudulent   or   involve   the   sale   of
                        counterfeit or stolen products;
                    </li>
                    <br/>

                    <li>
                        21.violates any law for the time being in force;
                    </li>
                    <br/>

                    <li>
                        22.deceives  or  misleads   the   addressee/users  about
                        the origin of such messages or communicates any
                        information   which   is   grossly   offensive   or
                        menacing in nature;
                    </li>
                    <br/>

                    <li>
                        23.impersonate another person;
                    </li>
                    <br/>

                    <li>
                        24.contains software viruses or any other computer
                        code,   files   or   programs   designed   to   interrupt,
                        destroy or limit the functionality of any computer
                        resource;   or  contains   any  trojan   horses,  worms,
                        time   bombs,   cancelbots,   easter   eggs   or   other
                        computer   programming   routines   that   may
                        damage,   detrimentally   interfere   with,   diminish
                        value   of,   surreptitiously   intercept   or   expropriate
                        any system, data or personal information;

                    </li>
                    <br/>


                    <li>
                        25.threatens the unity, integrity, defence, security or
                        sovereignty of India, friendly relations with foreign
                        states, or public order or causes incitement to the
                        commission   of   any   criminal   offence   or   prevents
                        investigation   of   any   offence   or   is   insulting   any
                        other nation;
                    </li>
                    <br/>

                    <li>
                        26.is false, inaccurate or misleading;
                    </li>
                    <br/>

                    <li>
                        27.directly   or   indirectly,   offers,   attempts   to   offer,
                        trades   or   attempts   to   trade   in   any   item,   the
                        dealing of which is prohibited or restricted in any
                        manner   under   the   provisions   of   any   applicable
                        law,   rule,   regulation   or   guideline   for   the   time
                        being in force; or
                    </li>
                    <br/>
                    <li>

                        28.creates   liability   for   us   or   causes   us   to   lose   (in
                        whole   or   in   part)   the   services   of   our   internet
                        service provider or other suppliers.
                    </li>
                    <br/>
                    <li>
                        2. You   shall   not   use   any   "deep-link",   "page-scrape",
                        "robot", "spider" or other automatic device, program,
                        algorithm or methodology, or any similar or equivalent
                        manual process, to access, acquire, copy or monitor
                        any portion of the Platform or any Content, or in any
                        way   reproduce   or   circumvent   the   navigational
                        structure   or   presentation   of   the   Platform   or   any
                        Content, to obtain or attempt to obtain any materials,
                        documents   or   information   through   any   means   not
                        purposely   made   available   through   the   Platform.   We
                        reserve our right to prohibit any such activity.
                    </li>
                    <br/>

                    <li>
                        3. You shall not attempt to gain unauthorized access to
                        any   portion   or  feature   of  the   Platform,  or   any  other
                        systems or networks connected to the Platform or to
                        any   server,   computer,   network,   or   to   any   of   the
                        services   offered   on   or   through   the   Platform,   by
                        hacking, "password mining"  or any other illegitimate
                        means.
                    </li>
                    <br/>

                    <li>
                        4. You shall not probe, scan or test the vulnerability of
                        the Platform or any network connected to the Platform
                        nor breach the security or authentication measures on
                        the Platform or any network connected to the Platform.
                        You may not reverse look-up, trace or seek to trace
                        any   information   on   any   other   user   of   or   visitor   to
                        Platform, or any other Buyer, including any account on
                        the Platform not owned by you, to its source, or exploit
                        the   Platform   or   any   service   or   information   made
                        available or offered by or through the Platform, in any
                        way where the purpose is to reveal any information,
                        including but not limited to personal identification or
                        information,   other   than   your   own   information,   as
                        provided for by the Platform.
                    </li>
                    <br/>

                    <li>
                        5. You   shall   not   make   any   negative,   denigrating   or
                        defamatory  statement(s)   or  comment(s)   about  us   or
                        the brand name or domain name used by us including
                        the name 'Benak Foods', or otherwise engage in any
                        conduct   or   action   that   might   tarnish   the   image   or
                        reputation, of Benak Foods or Merchant on platform or
                        otherwise tarnish or dilute any Benak Foods's trade or
                        service marks, trade name and/or goodwill associated
                        with such trade or service marks, as may be owned or
                        used by us. You agree that you will not take any action
                        that   imposes   an   unreasonable   or   disproportionately
                        large   load   on   the   infrastructure   of   the   Platform   or
                        Benak Foods's systems or networks, or any systems or
                        networks connected to Benak Foods.

                    </li>
                    <br/>

                    <li>

                        6. You agree not to use any device, software or routine to
                        interfere   or   attempt   to   interfere   with   the   proper
                        working   of   the   Platform   or   any   transaction   being
                        conducted on the Platform, or with any other person's
                        use of the Platform.
                    </li>
                    <br/>

                    <li>

                        7. You may not forge  headers  or  otherwise  manipulate
                        identifiers   in   order   to   disguise   the   origin   of   any
                        message or transmittal you send to us on or through
                        the Platform or any service offered on or through the
                        Platform. You may not pretend that you are, or that
                        you   represent,   someone   else,   or   impersonate   any
                        other individual or entity.
                    </li>
                    <br/>

                    <li>

                        8. You may not use the Platform or any content on the
                        Platform for any purpose that is unlawful or prohibited
                        by these Terms of Use, or to solicit the performance of
                        any illegal activity or other activity that infringes the
                        rights of Benak Foods and/or others.
                    </li>
                    <br/>


                    <li>
                        9. You shall at all times ensure full compliance with the
                        applicable provisions, as amended from time to time,
                        of (a) the Information Technology Act, 2000 and the
                        rules   thereunder;   (b)   all   applicable   domestic   laws,
                        rules and regulations (including the provisions of any
                        applicable   exchange   control   laws   or   regulations   in
                        force);   and   (c)   international   laws,   foreign   exchange
                        laws, statutes, ordinances and regulations (including,
                        but   not   limited   to   sales   tax/VAT,  income   tax,   octroi,
                        service tax, central excise, custom duty, local levies)
                        regarding   your   use   of   our   service   and   your   listing,
                        purchase, solicitation of offers to purchase, and sale of
                        products   or   services.   You   shall   not   engage   in   any
                        transaction in an item or service, which is prohibited
                        by   the   provisions   of   any   applicable   law   including
                        exchange   control   laws   or   regulations   for   the   time
                        being in force.
                    </li>
                    <br/>


                    <li>
                        10.In order to allow us to use the information supplied by
                        you,   without   violating   your   rights   or   any   laws,   you
                        agree   to   grant   us   a   non-exclusive,   worldwide,
                        perpetual,   irrevocable,   royalty-free,   sub-licensable
                        (through multiple tiers) right to exercise the copyright,
                        publicity, database rights or any other rights you have
                        in your Information, in any media now known or not
                        currently known, with respect to your Information. We
                        will only use your information in accordance with these
                        Terms of Use and Privacy Policy applicable to use of
                        the Platform.

                    </li>
                    <br/>

                    <li>
                        11.From   time   to   time,   you   shall   be   responsible   for
                        providing   information   relating   to   the   products   or
                        services   proposed   to   be   sold   by   you.   In   this
                        connection,   you   undertake   that   all   such   information
                        shall   be   accurate   in   all   respects.   You   shall   not
                        exaggerate   or   overemphasize   the   attributes   of   such
                        products or services so as to mislead other users in
                        any manner.

                    </li>
                    <br/>

                    <li>
                        12.You shall not engage in advertising to, or solicitation
                        of,   other   users   of   the   Platform   to   buy   or   sell   any
                        products   or   services,   including,   but   not   limited   to,
                        products or services related to that being displayed on
                        the  Platform  or related to us. You may  not transmit
                        any   chain   letters   or   unsolicited   commercial   or   junk
                        email   to   other   users   via   the   Platform.   It   shall   be   a
                        violation of these Terms of Use to use any information
                        obtained from the Platform in order to harass, abuse,
                        or   harm   another   person,   or   in   order   to   contact,
                        advertise   to,   solicit,   or   sell   to   another   person   other
                        than us without our prior explicit consent. In order to
                        protect our users from such advertising or solicitation,
                        we   reserve   the   right   to   restrict   the   number   of
                        messages or emails which a user may send to other
                        users   in   any   24-hour   period   which   we   deem
                        appropriate in its sole discretion. You understand that
                        we   have   the   right   at   all   times   to   disclose   any
                        information   (including   the   identity   of   the   persons
                        providing information or materials on the Platform) as
                        necessary   to   satisfy   any   law,   regulation   or   valid
                        governmental   request.   This   may   include,   without
                        limitation, disclosure of the information in connection
                        with   investigation   of   alleged   illegal   activity   or
                        solicitation of illegal activity or in response to a lawful
                        court order or subpoena. In addition, We can (and you
                        hereby   expressly   authorize   us   to)   disclose   any
                        information   about   you   to   law   enforcement   or   other
                        government   officials,   as   we,   in   our   sole   discretion,
                        believe   necessary   or   appropriate   in   connection   with
                        the investigation and/or resolution of possible crimes,
                        especially those that may involve personal injury.

                    </li>
                    <br/>

                    <li>
                        13.We reserve the right, but has no obligation, to monitor
                        the   materials   posted   on   the   Platform.   Benak   Foods
                        shall have the right to remove or edit any content that
                        in its sole discretion violates, or is alleged to violate,
                        any applicable law or either the spirit or letter of these
                        Terms of Use. Notwithstanding this right, YOU REMAIN
                        SOLELY   RESPONSIBLE   FOR   THE   CONTENT   OF   THE
                        MATERIALS   YOU   POST   ON   THE   PLATFORM   AND   IN
                        YOUR   PRIVATE   MESSAGES.   Please   be   advised   that
                        such   Content   posted   does   not   necessarily   reflect
                        Benak   Foods   views.   In   no   event   shall   Benak   Foods
                        assume or have any responsibility or liability for any
                        Content posted or for any claims, damages or losses
                        resulting   from   use   of   Content   and/or   appearance   of
                        Content   on   the   Platform.   You   hereby   represent   and
                        warrant that you have all necessary rights in and to all
                        Content   which   you   provide   and   all   information   it
                        contains and that such Content shall not infringe any
                        proprietary or other rights of third parties or contain
                        any   libellous,   tortious,   or   otherwise   unlawful
                        information.

                    </li>
                    <br/>

                    <li>
                        14.Your   correspondence   or   business   dealings   with,   or
                        participation in promotions of, advertisers found on or
                        through the Platform, including payment and delivery
                        of related products or services, and any other terms,
                        conditions,   warranties   or   representations   associated
                        with such dealings, are solely between you and such
                        advertiser. We shall not be responsible or liable for any
                        loss or damage of any sort incurred as the result of
                        any such dealings or as the result of the presence of
                        such advertisers on the Platform.

                    </li>
                    <br/>

                    <li>
                        15.It is possible that other users (including unauthorized
                        users or 'hackers') may post or transmit offensive or
                        obscene materials on the Platform and that you may
                        be   involuntarily   exposed   to   such   offensive   and
                        obscene   materials.   It   also   is   possible   for   others   to
                        obtain personal information about you due to your use
                        of the Platform, and that the recipient may use such
                        information to harass or injure you. We do not approve
                        of such unauthorized uses, but by using the Platform
                        You   acknowledge   and   agree   that   we   are   not
                        responsible   for   the   use   of   any   personal   information
                        that you publicly disclose or share with others on the
                        Platform.   Please   carefully   select   the   type   of
                        information   that   you   publicly   disclose   or   share   with
                        others on the Platform.

                    </li>
                    <br/>

                    <li>
                        16.Benak Foods shall have all the rights to take necessary
                        action and claim damages that may occur due to your
                        involvement/participation in any way on your own or
                        through   group/s   of   people,   intentionally   or
                        unintentionally   in   DoS/DDoS   (Distributed   Denial   of
                        Services), hacking, pen testing attempts  without our
                        prior consent or a mutual legal agreement.

                    </li>
                    <br/>

                    <li>
                        8. Account Registration

                    </li>
                    <br/>

                    <li>
                        1. You   may   access   the   Platform   by   registering   to   create   an
                        account (" Benak Foods Account") and become a member
                        (" Membership");   or   (c)   you   can   also   register   to   join   by
                        logging   into   your   account   with   certain   third   party   social
                        networking   sites   ("SNS")   (including,   but   not   limited   to,
                        Facebook); each such account, a " Third Party Account",
                        via   our   Platform,   as   described   below.   The   Membership   is
                        limited for the purpose and are subject to the terms, and
                        strictly not transferable. As part of the functionality of the
                        Platform services, you may link your Benak Foods Account
                        with Third Party Accounts, by either:

                    </li>
                    <br/>

                    <li>
                        1. providing your Third Party Account login information to
                        us through the Platform; or

                    </li>
                    <br/>

                    <li>

                        2. allowing us  to  access your Third  Party Account, as is
                        permitted   under   the   applicable   terms   and   conditions
                        that govern your use of each Third Party Account.
                    </li>
                    <br/>

                    <li>
                        2. You represent that you are entitled to disclose your Third
                        Party Account login information to us and/or grant us access
                        to your Third Party Account (including, but not limited to, for
                        use for the purposes described herein), without breach by
                        you of any of the terms and conditions that govern your use
                        of the applicable Third Party Account and without obligating
                        us   to   pay   any   fees   or   making   us   subject   to   any   usage
                        limitations imposed by such third party service providers.

                    </li>
                    <br/>

                    <li>
                        3. By   granting   us   access   to   any   Third   Party   Accounts,   you
                        understand that we will access, make available and store (if
                        applicable)   any   content   or   information   that   you   have
                        provided to and stored in your Third Party Account (" SNS
                        Content") so that it is available on and through the Platform
                        via your Benak Foods Account.

                    </li>
                    <br/>

                    <li>

                        4. Unless otherwise specified in these  Terms of Use, all SNS
                        Content, if any, will be considered to be your content for all
                        purposes of these Terms of Use.
                    </li>
                    <br/>

                    <li>
                        5. Depending   on   the   Third   Party   Accounts,   you   choose,   and
                        subject   to   the   privacy   settings   that   you   have   set   in   such
                        Third Party Accounts, personally identifiable information that
                        you post to your Third Party Accounts will be available on
                        and through your Benak Foods Account on the Platform.

                    </li>
                    <br/>

                    <li>

                        6. Please   note   that   if   a   Third   Party   Account   or   associated
                        service   becomes   unavailable   or   our   access   to   such   Third
                        Party   Account   is   terminated   by   the   third   party   service
                        provider, then SNS Content will no longer be available on
                        and through the Platform.
                    </li>
                    <br/>
                    <li>


                        7. We will create your Benak Foods Account for your use of the
                        Platform services based upon the personal information you
                        provide to us or that we obtain via SNS, as described above.
                        You can only have one Benak Foods Account and are not
                        permitted to create multiple accounts. Benak Foods reserves
                        the right to suspend such multiple accounts without being
                        liable   for   any   compensation   where   you   have   created
                        multiple accounts on the Platform.
                    </li>
                    <br/>

                    <li>
                        8. You   agree   to   provide   accurate,   current   and   complete
                        information during the registration process and update such
                        information to keep it accurate, current and complete.

                    </li>
                    <br/>

                    <li>

                        9. We reserve the right to suspend or terminate your Benak
                        Foods   Account   and   your   access   to   the   Services   (i)   if   any
                        information   provided   during   the   registration   process   or
                        thereafter   proves   to   be   inaccurate,   not   current   or
                        incomplete; (ii) if it is believed that your actions may cause
                        legal liability for you, other users or us; and/or (iii) if you are
                        found to be non-compliant with the Terms of Use or other
                        Benak Foods policies.
                    </li>
                    <br/>

                    <li>
                        10.You   are   responsible   for   safeguarding   your   password.   You
                        agree that you will not disclose your password to any third
                        party   and   that   you   will   take   sole   responsibility   for   any
                        activities   or   actions   under   your   Benak   Foods   Account,
                        whether   or   not   you   have   authorized   such   activities   or
                        actions. You will immediately notify us of any unauthorized
                        use of your Benak Foods Account.

                    </li>
                    <br/>

                    <li>
                        11.Goods   and   services   purchased   from   the   Platform   are
                        intended for your personal use and you represent that the
                        same are not for resale or you are not acting as an agent for
                        other parties.

                    </li>
                    <br/>

                    <li>
                        9. Order Booking and Financial Terms

                    </li>
                    <br/>

                    <li>
                        1. The  Platform   allows  the  Buyers to  place  Orders  and  upon
                        acceptance of such Orders by the Merchants, Benak Foods
                        will,   subject   to   the   terms   and   conditions   set   out   herein,
                        facilitates   delivery   of   goods   or   services,   or   completion   of
                        Tasks through PDP.

                    </li>
                    <br/>

                    <li>
                        2. Benak   Foods   does   not   own,   sell,   resell   on   its   own   such
                        products offered by the Merchants, and/or does not control
                        the Merchants or the related services provided in connection
                        thereof.   Buyer   understands   that   any   Order   that   he/she
                        places shall be subject to the terms and conditions set out in
                        these   Terms   of   Use   including,   but   not   limited   to,   product
                        availability, delivery location serviceability, and acceptance
                        of Orders by Merchants/PDPs.

                    </li>
                    <br/>

                    <li>
                        3. As   a   general   rule,   all   Orders   placed   on   the   Platform   and
                        Delivery Services are treated as confirmed.

                    </li>
                    <br/>

                    <li>
                        4. However, upon Buyer’s successful completion of booking an
                        Order, we may call the Buyer on the telephone or mobile
                        number provided to confirm the details of such Order, price
                        to be paid and the estimated delivery time. For this purpose,
                        Buyer will be required to share certain information with us,
                        including but not limited to Buyer’s (i) first and last name (ii)
                        mobile number; and (iii) email address. It shall be Buyer’s
                        sole   responsibility   to   bring   any   incorrect   details   to   our
                        attention.

                    </li>
                    <br/>

                    <li>
                        5. In addition  to the  foregoing, we  may  also  contact you  by
                        phone and / or email to inform and confirm any change in
                        the Order, due to availability or unavailability or change in
                        Order   or   change   in   price   of   any   item   in   the   Order   as
                        informed by the Merchant. Please note that any change or
                        confirmation   of   the   Order   shall   be   treated   as   final.   It   is
                        clarified   that   Benak   Foods   reserves   the   right   to   not   to
                        process Buyer's Order in the event Buyer or Merchant or PDP
                        is   unavailable   on   the   phone   or   any   other   means   of
                        communication at the time when we call you for confirming
                        the Order and such event the provisions of the Cancellation
                        and Refund Policy shall be applicable.

                    </li>
                    <br/>

                    <li>

                        6. All payments made against the Orders or Services on the
                        Platform   by   you   shall   be   compulsorily   in   Indian   Rupees
                        acceptable   in   the   Republic   of   India.   The   Platform   will   not
                        facilitate   transactions   with   respect   to   any   other   form   of
                        currency   with   respect   to   the   Orders   or   Services   made   on
                        Platform. You can pay by (i) credit card or debit card or net
                        banking; (ii) any other RBI approved payment method at the
                        time of booking an Order; or (iii) credit or debit card or cash
                        at the time of delivery. You understand, accept and agree
                        that the payment facility provided by Benak Foods is neither
                        a   banking   nor   financial   service   but   is   merely   a   facilitator
                        providing   an   electronic,   automated   online   electronic
                        payment,   receiving   payment   on   delivery,   collection   and
                        remittance facility for the transactions on the Platform using
                        the   existing   authorized   banking   infrastructure   and   credit
                        card   payment   gateway   networks.   Further,   by   providing
                        payment facility, Benak Foods is neither acting as trustees
                        nor   acting   in   a   fiduciary   capacity   with   respect   to   the
                        transaction or the transaction price.
                    </li>
                    <br/>

                    <li>
                        7. Buyers acknowledge and agree that Benak Foods acts as the
                        Merchant's and PDP’s payment agent for the limited purpose
                        of accepting payments from Buyers/Merchants on behalf of
                        the   Merchant   or   PDP,   as   the   case   may   be.   Upon   your
                        payment of amounts to us, which are due to the Merchant or
                        PDP,  your  payment  obligation  to   the   Merchant  or  PDP   for
                        such   amounts   is   completed,   and   we   are   responsible   for
                        remitting such amounts to the Merchant or PDP. You shall
                        not,   under   any   circumstances   whatsoever,   make   any
                        payment directly to the Merchant for Order bookings or to
                        the PDP for delivery of the Order or completion of the Task
                        made using the Platform.

                    </li>
                    <br/>

                    <li>
                        8. Buyer   agrees   to   pay   for   the   total   amount   for   the   Order
                        placed  on   the   Platform.  Benak   Foods  will   collect  the   total
                        amount   in   accordance   with   these   Terms   of   Use   and   the
                        pricing terms set forth in the applicable listing of product or
                        restaurant   service   for   the   particular   Merchant,   apart   from
                        the delivery fees for Delivery Services. Please note that we
                        cannot control any amount that may be charged to Buyer by
                        his/her bank related to our collection of the total amount,
                        and we disclaim all liability in this regard.

                    </li>
                    <br/>


                    <li>
                        9. In  connection  with Buyer’s  Order,  he/she   will  be  asked  to
                        provide customary billing information such as name, billing
                        address and credit card information either to us or our third
                        party  payment   processor.   Buyer   agrees   to   pay   us   for  the
                        Order   placed   by   you   on   the   Platform,   in   accordance   with
                        these Terms, using the methods described under clause VIII
                        (6)   above.   Buyer   hereby  authorizes   the   collection  of   such
                        amounts   by   charging   the   credit   card   provided   as   part   of
                        requesting the booking, either directly by us or indirectly, via
                        a   third   party   online   payment   processor   or   by   one   of   the
                        payment   methods   described   on   the   Platform.   If   Buyer   is
                        directed to our third-party payment processor, he/she may
                        be   subject   to   terms   and   conditions   governing   use   of   that
                        third   party's   service   and   that   third   party's   personal
                        information   collection   practices.   Please   review   such   terms
                        and conditions and privacy policy before using the Platform
                        services.   Once   the   Order   is   confirmed   you   will   receive   a
                        confirmation email summarizing the confirmed booking.

                    </li>
                    <br/>

                    <li>
                        10.The final tax bill will be issued by the Merchant and PDP (if
                        registered   for   tax   purposes)   to   the   Buyer   along   with   the
                        Order and Benak Foods is merely collecting the payment on
                        behalf of such Merchant and PDP. All applicable taxes and
                        levies, the rates thereof and the manner of applicability of
                        such taxes on the bill are being charged and determined by
                        the Merchant and PDP. Benak Foods holds no responsibility
                        for the legal correctness/validity of the levy of such taxes.
                        The   sole   responsibility   for   any   legal   issue   arising   on   the
                        taxes shall reside with the Merchant and the PDP.

                    </li>
                    <br/>

                    <li>
                        11.The prices reflected on the Platform, including packaging or
                        handling   charges,   are   determined   solely   by   the   Merchant
                        and are listed based on Merchant's information. Very rarely,
                        prices   may   change   at   the   time   of   placing   Order   due   to
                        Merchant changing the menu price without due intimation
                        and such change of price are at the sole discretion of the
                        Merchant attributing to various factors beyond control.
                        Disclaimer: Prices on any product(s) as reflected on the Platform
                        may due to some technical issue, typographical error or product
                        information supplied by Merchant be incorrectly reflected and in
                        such an event Merchant may cancel Buyer’s Order(s).

                    </li>
                    <br/>

                    <li>
                        12.The   Merchant   shall   be   solely   responsible   for   any
                        warranty/guarantee   of   the   goods   or   services   sold   to   the
                        Buyers and in no event shall be the responsibility of Benak
                        Foods.

                    </li>
                    <br/>

                    <li>
                        13.The   transactions   are   bilateral   between   the   Merchant   and
                        Buyer,   and   between   Merchant/Buyer and   PDP,   therefore,
                        Benak   Foods   is   not   liable   to   charge   or   deposit   any   taxes
                        applicable on such transactions.

                    </li>
                    <br/>


                    <li>
                        10.Cancellations and Refunds

                    </li>
                    <br/>

                    <li>
                        Please  refer to  the  Cancellation and Refund Policy for  cancellation
                        and refunds terms in relation to usage of the Platform for availing
                        Services.


                    </li>
                    <br/>

                    <li>
                        11.Terms of service

                    </li>
                    <br/>

                    <li>
                        1. The Buyer agrees and acknowledges that Benak Foods shall
                        not be responsible for:

                    </li>
                    <br/>

                    <li>
                        3. The   services   or   goods   provided   by   the   Merchants
                        including   but   not   limited   to   serving   of   food   Orders
                        suiting your requirements and taste;

                    </li>
                    <br/>

                    <li>

                        4. The Merchant's services or goods, or services provided
                        by PDPs not being up to Buyer expectations or leading
                        to any loss, harm or damage to him/her;
                    </li>
                    <br/>

                    <li>
                        5. The availability or unavailability of certain items on the
                        menu;

                    </li>
                    <br/>

                    <li>
                        6. The Merchant serving the incorrect Orders; or

                    </li>
                    <br/>

                    <li>
                        7. Product liability of goods provided by Merchants.

                    </li>
                    <br/>

                    <li>
                        2. The   details   of   the   menu   and   price   list   available   on   the
                        Platform with respect to restaurant services, goods or any
                        other services are based on the information provided by the
                        Merchants and Benak Foods shall not be responsible for any
                        change or cancellation or unavailability.

                    </li>
                    <br/>

                    <li>
                        3. Buyers and Merchants agree and acknowledge that Benak
                        Foods   is   not   responsible   for   any   liability   arising   out   of
                        delivery services provided by PDP to them.

                    </li>
                    <br/>

                    <li>
                        4. Buyers  may  not be  able to avail  Services if their delivery
                        location is outside Benak Foods’s current scope of Service.
                        Benak Foods will keep the Buyer informed of the same at the
                        time of confirming his/her Order booking.

                    </li>
                    <br/>

                    <li>

                        5. Buyer understands that delivery time quoted at the time of
                        confirming the Order is an approximate estimate and may
                        vary   based   on   the   information   obtained   from   PDPs   and
                        Merchants. Benak Foods will not be responsible for any delay
                        in the delivery of an Order.
                    </li>
                    <br/>

                    <li>
                        6. Buyer   understands   that   there   are   certain   Merchants   who
                        undertake delivery of their goods and services to the Buyer
                        and the Merchant may charge the Buyer for such service.
                        Benak Foods exercises no control on such delivery services
                        and same shall be under the control of Merchant alone and
                        hence   all   or   any   disputes   arising   out   of   such   delivery
                        services shall be between Buyer and Merchant alone. Benak
                        Foods shall not be responsible for such delivery services and
                        assumes no liability for disputes arising out of the same.

                    </li>
                    <br/>

                    <li>

                        7. Buyer’s   Order   will   be   only   delivered   to   the   address
                        designated by him/her at the time of placing the Order on
                        the Platform. Buyer’s Order will be cancelled in the event of
                        any   change   of   the   address   as   informed   by   the   PDP   and
                        Buyer   shall   not   be   entitled   to   any   refund   for   the   same.
                        Delivery of goods and services in the event of change of the
                        delivery location shall be subject to acceptance by the PDP
                        or sole discretion of Benak Foods.
                    </li>
                    <br/>

                    <li>

                        8. The Buyer shall undertake to provide adequate directions,
                        information   and   authorisations   to   accept   delivery.   In   the
                        event of no delivery due to any act or omission attributable
                        to  Buyer, the  goods  or  services  shall  be   deemed  to  have
                        been delivered to the Buyer and all risk and responsibility in
                        relation   thereto   shall   pass   to   the   Buyer   without   being
                        entitled to any refund.
                    </li>
                    <br/>

                    <li>
                        9. The   Buyer   understands   that   Benak   Foods’s   (including
                        Merchant’s   and   PDP’s)   liability   ends   once   Order   has   been
                        delivered to him/her, except where the product liability of
                        the Merchant subsists.

                    </li>
                    <br/>

                    <li>

                        10.Services provided:
                    </li>
                    <br/>

                    <li>

                        1. You agree and acknowledge that Benak Foods shall be
                        liable   in   the   event   you   have   failed   to   adhere   to   the
                        Terms of Use.
                    </li>
                    <br/>

                    <li>

                        2. Buyer shall be required to provide credit or debit card
                        details   to   the   approved   payment   gateways   while
                        making   the   payment   on   the   Platform.   In   this   regard,
                        Buyer   agrees   to   provide   correct   and   accurate   credit/
                        debit card details to the approved payment gateways
                        for availing the Services. Buyer shall not use the credit/
                        debit card which is not lawfully owned by Buyer, i.e. in
                        any   transaction,   Buyer   must   use   his/her   own   credit/
                        debit card. The information provided by the Buyer will
                        not   be   utilized   or   shared   with   any   third   party   unless
                        required   in   relation   to   fraud   verifications   or   by   law,
                        regulation   or   court   order.   Buyer   shall   be   solely
                        responsible   for   the   security   and   confidentiality   of
                        his/her credit/ debit card details. We expressly disclaim
                        all liabilities that may arise  as  a consequence  of any
                        unauthorized use of your credit/ debit card.
                    </li>
                    <br/>

                    <li>

                        11.Benak Foods does not offer  any refunds against  goods or
                        services already purchased from a Merchant or PDP through
                        the Platform unless an error that is directly attributable to
                        Benak   Foods   has   occurred   during   the   purchase   of   such
                        product or services.
                    </li>
                    <br/>

                    <li>
                        12.We   constantly   strive   to   provide   you   with   accurate
                        information   on   the   Platform.   However,   in   the   event   of   an
                        error,   we   may,   in   our   sole   discretion,   contact   you   with
                        further instructions.

                    </li>
                    <br/>

                    <li>
                        13.If you use the Platform, you do the same at your own risk.

                    </li>
                    <br/>

                    <li>
                        14.Buyer agrees that the Services shall be provided through the
                        Platform   only   during   the   working   hours   of   the   relevant
                        Merchants and PDPs.

                    </li>
                    <br/>

                    <li>

                        12.No Endorsement
                    </li>
                    <br/>

                    <li>

                        1. We   do   not   endorse   any   Merchant.   In   addition,   although
                        these   Terms   of   Use   require   you   to   provide   accurate
                        information,   we   do   not   attempt   to   confirm,   and   do   not
                        confirm if it is purported identity. We will not be responsible
                        for   any   damage   or   harm   resulting   from   your   interactions
                        with other Members.
                    </li>
                    <br/>

                    <li>

                        2. By using the Services, you agree that any legal remedy or
                        liability that you seek to obtain for actions or omissions of
                        other   Members   or   other   third   parties   will   be   limited   to   a
                        claim against the particular Members or other third parties
                        who   caused   you   harm   and   you   agree   not   to   attempt   to
                        impose liability on, or seek any legal remedy from us with
                        respect to such actions or omissions.
                    </li>
                    <br/>
                </ul>
            </p>
        </div >
    )
}

export default TermsCondition
