import React, { useState } from 'react'

const ConfirmBox = ({onClickClose,onClickYes}) => {

  
    

    return (

        <div className={` tw-fixed tw-flex tw-justify-center tw-items-center tw-bg-black/40 tw-w-full  tw-z-[200] tw- tw-p-4 tw-overflow-x-hidden tw-overflow-y-auto tw-md:inset-0 tw-h-[calc(100%-1rem)] tw-max-h-full`}>
            <div className="tw-relative  tw-max-w-md tw-max-h-full">
         
                <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow tw-dark:bg-gray-700">
                <button onClick={()=>onClickClose(false)} type="button" className=" tw-absolute tw-top-0 tw-right-0 tw-flex tw-w-10 tw-rounded-lg tw-bg-transparent hover:tw-text-red-600" >
                        <svg aria-hidden="true" className="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

                    </button>
               
                    <div className="tw-p-6 tw-text-center">
                        <svg aria-hidden="true" className="tw-mx-auto tw-mb-4 tw-text-gray-400 tw-w-14 tw-h-14 tw-dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <h3 className="tw-mb-5 tw-text-lg tw-font-normal tw-text-gray-500 tw-dark:text-gray-400">Are you sure you want to remove this item?</h3>

                        <div className='tw-flex '>


                            <button onClick={onClickYes} type="button" className="text-white tw-bg-red-600 hover:tw-bg-red-800  tw-font-medium tw-rounded-lg tw-text-sm tw-inline-flex tw-items-center tw-px-5 tw-py-2.5 tw-text-center tw-mr-2 tw-transition-colors tw-justify-center">
                                Yes
                            </button>
                            <button onClick={()=>onClickClose(false)} type="button" className="text-white tw-bg-gray-600 hover:tw-bg-gray-800   tw-font-medium tw-rounded-lg tw-text-sm tw-inline-flex tw-items-center tw-px-5 tw-py-2.5 tw-text-center tw-mr-2 tw-transition-colors tw-justify-center">No</button>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default ConfirmBox