import React from 'react'
import Footer from './footer/Footer'
import { Button } from 'react-bootstrap'
import Header from './header/Header'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCartItems, selectCartTotal } from '../features/cart/cartSlice'

const Checkout = () => {
   const items = useSelector(selectCartItems)
   const total = useSelector(selectCartTotal)

    useEffect(() => {
        window.scrollTo(0, 0);
        
    }, []);


const onClick =(user)=>{
}

    return (
        <>
            <Header />
            <section className='tw-container tw-min-h-screen tw-mx-auto tw-bg-white '>
                <div className=' tw-mt-20 '>
                    <h2 className='tw-text-3xl tw-font-bold tw-text-center tw-text-gray-800 '>Checkout</h2>
                </div>
                <div className='tw-grid tw-grid-cols-6   tw-h-full lg:tw-w-4/6 tw-w-5/6 tw-mx-auto tw-mb-8 ' >



                    <div className='lg:tw-col-span-3 tw-col-span-6 tw-py-10 px-10' >
                        <h2 className='tw-text-xl tw-mb-6'>Address</h2>

                        <form>
                            <div className="tw-mb-6">

                                <input type="text" id="name" className="  tw-border-none tw-outline tw-outline-gray-200 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-px-2 tw-py-2 focus:tw-outline-gray-200 tw-w-full" placeholder="Name" required />
                            </div>
                            <div className="tw-mb-6">

                                <input type="text"  className="  tw-border-none tw-outline tw-outline-gray-200 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-px-2 tw-py-2 focus:tw-outline-gray-200 tw-w-full" placeholder="Apartment" required />
                            </div>
                            <div className="tw-mb-6">

                                <input type="text"  className="  tw-border-none tw-outline tw-outline-gray-200 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-px-2 tw-py-2 focus:tw-outline-gray-200 tw-w-full" placeholder="Street address" required />
                            </div>
                            <div className="tw-mb-6">

                                <input type="text"  className="  tw-border-none tw-outline tw-outline-gray-200 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-px-2 tw-py-2 focus:tw-outline-gray-200 tw-w-full" placeholder="Town / City" required />
                            </div>



                        </form>

                    </div>

                    <div className='lg:tw-col-span-3 tw-col-span-6  tw-py-10 lg:tw-mx-10 '>
                        <h2 className='tw-text-xl tw-mb-6 '>Your Order</h2>
                        <div className='  tw-rounded-md'>
                           
                           {items?.map((item,index)=>{
                            return <div key={index} className='tw-flex tw-justify-between  '>
                            <p className='tw-text-gray-700 tw-text-base'>{item.title} x {item.quantity}</p>
                            <p className='tw-text-gray-900 tw-text-base'>₹{item.price}</p>
                        </div>
                           })}
                            
                           
                           
                            <div className='tw-flex tw-justify-between tw-mt-1'>

                                <p className='tw-text-gray-700 tw-text-base'>Taxes & Other Charges</p>
                                <p className='tw-text-gray-900 tw-text-base'>₹40</p>
                            </div>
                            <div className='tw-flex tw-justify-between tw-mt-1'>

                                <p className='tw-text-gray-700 tw-text-base tw-font-semibold'>To Pay</p>
                                <p className='tw-text-gray-700 tw-text-base tw-font-semibold'>₹{total+40}</p>
                            </div>
                        </div>
                        <div className='tw-mt-10 '>
                            <Button onClick={()=>onClick()} >Pay
                            </Button>
                        </div>

                    </div>

                </div>

            </section>
            <Footer />
        </>
    )
}

export default Checkout