import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import { FiInstagram, FiYoutube, FiTwitter, FiLinkedin, FiFacebook } from 'react-icons/fi'
import { RiMessengerLine } from 'react-icons/ri'
import { Divider } from '@mui/material'

const Footer = () => {
    return (
        <>
            <div className='foct'>
                <footer className='usfll'>
                    <span className='txt'> Useful Links </span>
                    <div>
                        <div> <NavLink to={{}} className='usfl'>Who We Are </NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Blog</NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Work With Us </NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Investor Relations  </NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Report Fraud</NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Contact Us </NavLink>  </div>
                        <div> <NavLink to={{}} className='usfl'>Partner With Us </NavLink>  </div>
                    </div>
                </footer>
                <footer className='lnk'>
                    <div className='contain'>
                        <div>
                            <span className='txt'>Find Us On</span>
                        </div>
                        <div className='contact'>
                            <NavLink to={{}} className='icon'> <FiYoutube /></NavLink>
                            <NavLink to={{}} className='icon'> <FiLinkedin /></NavLink>
                            <NavLink to={{}} className='icon'> <FiFacebook /></NavLink>
                            <NavLink to={{}} className='icon'> <RiMessengerLine /></NavLink>
                            <NavLink to={{}} className='icon'> <FiInstagram /></NavLink>
                            <NavLink to={{}} className='icon'> <FiTwitter /></NavLink>
                        </div>
                    </div>
                    <div className='contain1'>
                        <div>
                            <span className='txt'>Contact Us On</span>
                        </div>
                        <div className='contact'>
                            <span className='tcpp'> +91 9878887878 </span>
                        </div>
                    </div>
                </footer>

{/* 


                <footer className='lnk'>
                    
                </footer> */}

                

                <Divider />
                <footer className='copy'>

                    <div>
                        <span className='usfl' >Copyright &copy; 2023 Benak Foods | All Rights Reserved. </span>

                    </div>
                    <div>
                        <NavLink to='/termsconditions' className='usfl'>   Terms & Conditions  | Privacy Policy </NavLink>
                    </div>

                </footer>
            </div>
        </>
    )
}

export default Footer
