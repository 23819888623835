import React from 'react'
import Header from './header/Header'
// import MultipleItems from './carousel/Carousel'
import Cards from './cards/Cards'
import Footer from './footer/Footer'
import Uncontrol from './carousel/Carousel'

const Home = () => {
  return (
    <div>
      <Header/>
      <Uncontrol/>
      <Cards/>
      <Footer/>
    </div>
  )
}

export default Home
