import React, { useState } from 'react'
import image from '../components/cards/i18.jpg'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Header from './header/Header'
import Footer from './footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, decreaseQuantity, removeFromCart, selectCartItems, selectCartTotal } from '../features/cart/cartSlice'
import ConfirmBox from './ConfirmBox'


const Card = ({ title, description, image, price, quantity, onClickIncrease, onClickDecrease, onDelete, }) => {



    return (
        <div className='tw-grid tw-grid-cols-12  tw-bg-white tw-items-center tw-drop-shadow-lg tw-border tw-border-gray-100 tw-rounded-md tw-my-2 tw-p-4'>
            <img src={image} alt='food' className='lg:tw-col-span-3 tw-col-span-6 tw-rounded-lg tw-object-cover tw-h-40 tw-w-40 tw-drop-shadow-md' />
            <div className='lg:tw-col-span-5 tw-col-span-6 tw-mx-4 '>
                <div>
                    <h5 className='tw-text-sm tw-font-semibold tw-text-gray-700'>{title}</h5>
                    <p className='tw-text-sm   tw-text-gray-400 tw-capitalize'>{description}</p>
                </div>

                <h5 className='tw-text-base tw-font-semibold  tw-text-gray-600   '>₹{price}</h5>
            </div>


            <div className='tw-flex border tw-border-gray-50 lg:tw-col-span-2
             tw-col-span-6  tw-rounded-xl tw-items-center lg:tw-w-9/12 tw-w-40 lg:tw-mx-auto tw-shadow-sm lg:tw-mt-0 tw-mt-4  '>


                <button onClick={onClickDecrease} className='tw-bg-transparent hover:tw-scale-110  tw-transition-transform tw-group'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="tw-w-5 tw-h-5 group-hover:tw-text-[#00ffff] tw-transition-all">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                    </svg>

                </button>
                <span className='tw-font-semibold tw-text-gray-600'>{quantity}</span>
                <button onClick={onClickIncrease} className='tw-bg-transparent hover:tw-scale-110  tw-transition-transform tw-group'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="tw-w-5 tw-h-5 group-hover:tw-text-[#00ffff] tw-transition-all">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>

                </button>
            </div>
            <div className='lg:tw-col-span-2 tw-col-span-6 lg:tw-w-2/3  tw-w-20 tw-justify-self-end tw-h-full  tw-flex tw-items-center' >
                <button onClick={onDelete} className='tw-bg-transparent  tw-group'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="tw-w-6 tw-h-6 tw-text-gray-500 group-hover:tw-text-[#00ffff] group-hover:tw-scale-110 tw-transition-all">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                </button>
            </div>
        </div>
    )
}

const Cart = () => {

    const navigate = useNavigate()
    const [confirmBox, setconfirmBox] = useState(false)
    const [itemId, setitemId] = useState()


    const items = useSelector(selectCartItems)
    const total = useSelector(selectCartTotal)


    const dispatch = useDispatch()


    const onClickDecrease = (item) => {
        const id = item.id
        if (item.quantity <= 1) {
            setitemId(id)
            setconfirmBox(true)
        } else {
            dispatch(decreaseQuantity(id))

        }
    }

    const onClickIncrease = (item) => {
        dispatch(addToCart(item))
    }

    const onDelete = (id) => {
        // remove by id
        console.log(id);
        setitemId(id)
        setconfirmBox(true)

    }

    const onClickYes = () => {
        dispatch(removeFromCart(itemId))
        setconfirmBox(false)
        setitemId('')
    }






    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Header />
            {confirmBox && <ConfirmBox onClickYes={() => onClickYes()} onClickClose={setconfirmBox} />}

            <section className='tw-container tw-min-h-screen tw-mx-auto tw-bg-white '>
                <div className=' tw-mt-20 '>
                    <h2 className='tw-text-3xl tw-font-bold tw-text-center tw-text-gray-800 '>Your Cart</h2>
                </div>
                {items == '' ?
                    <div>
                        <h5 className='tw-text-center tw-my-10'>Your cart is empty</h5>
                    </div>
                    :


                    <div className='tw-grid tw-grid-cols-6  tw-h-full'>


                        <div className='lg:tw-col-span-4   tw-col-span-6    tw-py-10 ' >
                            {items?.map((item, index) => {
                                return <Card key={index} title={item.title} description={item.description} onClickDecrease={() => onClickDecrease(item)} onClickIncrease={() => onClickIncrease(item)} image={item.img} onDelete={() => onDelete(item.id)} price={item.price} quantity={item.quantity} />
                            })}





                        </div>



                        <div className='lg:tw-col-span-2  tw-col-span-6 tw-bg-green-20 tw-py-10 tw-mx-10 '>
                            <h2 className='tw-text-xl tw-mb-4'>Order summary</h2>
                            <div className='tw-flex tw-justify-between tw-mt-1'>

                                <p className='tw-text-gray-700 tw-text-base'>Item Total</p>
                                <p className='tw-text-gray-900 tw-text-base'>₹{total}</p>
                            </div>
                            <div className='tw-flex tw-justify-between tw-mt-1'>

                                <p className='tw-text-gray-700 tw-text-base'>Taxes & Other Charges</p>
                                <p className='tw-text-gray-900 tw-text-base'>₹40</p>
                            </div>
                            <div className='tw-flex tw-justify-between tw-mt-1'>

                                <p className='tw-text-gray-700 tw-text-base tw-font-semibold'>To Pay</p>
                                <p className='tw-text-gray-700 tw-text-base tw-font-semibold'>₹{total + 40}</p>
                            </div>
                            <div className='tw-mt-10'>
                                <Button onClick={() => navigate('/checkout')}  >Proceed to checkout
                                </Button>
                            </div>

                        </div>

                    </div>
                }

            </section>
            <Footer />
        </>
    )
}

export default Cart