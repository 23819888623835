import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUsersAPI } from "./userDataAPI";




export const fetchUsers = createAsyncThunk(
    'users/fetchUsers', async () => {
      const response = await fetchUsersAPI();
      console.log(response);
      return response.data;
    }
  );



const userSlice = createSlice({
    name: 'users',
  initialState: {
    data: [],
    status: 'idle',
    error: null
  },
    reducers: {
        addUser: (state, action) => {
            state.data.push(action.payload)
        }
    },
    extraReducers: builder => {
        builder
          .addCase(fetchUsers.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
          })
          .addCase(fetchUsers.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }


})

export default userSlice.reducer

export const {addUser} = userSlice.actions

export const selectUser = state => state.users.data;
export const selectStatus = state => state.users.status;
export const selectError = state => state.users.error;