import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MultipleItems from './components/carousel/Carousel'
import Cards from './components/cards/Cards';
import TermsCondition from './components/tc/TermsCondition';
import Home from './components/Home';
import Checkout from './components/Checkout';
import { useEffect } from 'react';
import Cart from './components/Cart';

function App() {
  

  return (
    <BrowserRouter>
     <Routes>
     <Route path='/' element={<Home/>} />
     <Route path='/cart' element={<Cart/>} />
     
     <Route path='/checkout' element={<Checkout/>} />

      <Route path='/termsconditions' element={<TermsCondition/>} />
      </Routes>     
    </BrowserRouter>
  );
}

export default App;
