import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, IconButton, Input } from '@mui/material'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import './Header.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../features/cart/cartSlice';





const Header = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState();

    const items = useSelector(selectCartItems)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    // console.log('header')
    const ariaLabel = { 'aria-label': 'description' };


    const submitdata = () => {
        console.log(otp)
        handleClose();
        handleClose1();
    }

    return (
        <>

            <nav  >

                <div className='rnav'>
                    <h3 style={{ fontWeight: 'bold' }}
                    >BENAK FOODS
                    </h3>

                    <div className='searchbar'>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 0, width: '30ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div className='bar'>
                                <Input placeholder="Search" inputProps={ariaLabel} className="testing" autoComplete='off' />
                                <NavLink to={{}}>
                                    <div className='hov'>   <SearchOutlinedIcon style={{ color: 'black' }} />
                                    </div>
                                </NavLink>
                            </div>
                        </Box>
                    </div>
                    <div className='icons'>
                        <div className='hov'><HeadsetMicIcon /></div>


                        <div className='hov ' onClick={() => navigate('/cart')}><Badge badgeContent={items?.length} color="primary">
                            <ShoppingCartOutlinedIcon />
                        </Badge></div>

                        <div className='hov' onClick={handleClickOpen}> <AccountCircleOutlinedIcon /></div>

                    </div>

                </div>






                <ul className='hide'>
                    <li>
                        <div>
                            <h3 onClick={() => navigate('/')} style={{ fontWeight: 'bold' }}
                            >BENAK FOODS</h3>
                        </div>
                    </li>
                </ul>

                <ul className='flx hide'>
                    <li className='searchbar' style={{ marginRight: '20px' }}>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 0, width: '30ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div className='bar'>
                                <Input placeholder="Search" inputProps={ariaLabel} className="testing" autoComplete='off' />
                                <NavLink to={{}}>
                                    <div className='hov'>   <SearchOutlinedIcon style={{ color: 'black' }} /></div>
                                </NavLink>
                            </div>
                        </Box>
                    </li>
                    <li>
                        <div className='hov'><HeadsetMicIcon /></div>
                    </li>
                    <li>
                        <div className='hov ' onClick={() => navigate('/cart')}><Badge badgeContent={items?.length} color="primary">
                            <ShoppingCartOutlinedIcon />
                        </Badge></div>
                    </li>
                    <li>
                        <div className='hov' onClick={handleClickOpen}> <AccountCircleOutlinedIcon /></div>
                    </li>
                </ul>


                <Dialog open={open} onClose={handleClose} >
                    <div style={{ margin: '30px 50px' }}>
                        <DialogTitle style={{ paddingRight: '150px', fontSize: '28px' }}>Welcome Back !</DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText>
                           Please enter your mobile number
                        </DialogContentText> */}
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Mobile Number"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                handleClose();
                                handleClickOpen1();
                            }} style={{ backgroundColor: '#ececec' }}>Send OTP</Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <Dialog open={open1} onClose={handleClose1} style={{ width: '100%' }}>
                    <div style={{ margin: '20px' }}>
                        <DialogTitle style={{ paddingRight: '150px', fontSize: '28px' }}>Enter OTP </DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText>
                           Please enter your mobile number
                        </DialogContentText> */}
                            <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure style={{ padding: '30px' }} />
                            <ResendOTP onResendClick={() => console.log("Resend clicked")} className='otp-resend-root' />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={submitdata} style={{ backgroundColor: '#ececec' }}>Submit</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </nav>

        </>
    )
}

export default Header
