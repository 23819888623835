import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import img0 from './f1.jpg'
import img1 from './food1.jpg'
import img3 from './f3.jpg'
import f4 from './f4.jpg'
import f5 from './f5.jpg'
import f6 from './f6.jpg'
import f7 from './f7.jpg'
import './caro.css'

function Uncontrol() {
  return (
    <div style={{padding:'10px 10px 0 10px ',}}>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img0}
            alt="First slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="Second slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Third slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={f4}
            alt="Third slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={f5}
            alt="Third slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={f6}
            alt="Third slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={f7}
            alt="Third slide"
            style={{ width: "100%", height: "400px", objectFit: 'cover' }}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Uncontrol;